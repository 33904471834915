(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/oddset/common/assets/javascripts/constants.js');
"use strict";


const KambiChannel = {
  WEB: 'WEB',
  MOBILE: 'MOBILE'
};
const toExport = {
  KambiChannel
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.oddset.common.constants', toExport);
}

 })(window);