(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/common/assets/javascripts/client-errors.js') >= 0) return;  svs.modules.push('/components/oddset/common/assets/javascripts/client-errors.js');
"use strict";


const ErrorType = {
  KAMBI_COUPON: 'coupon',
  KAMBI_BET: 'bet',
  KAMBI_SELECTION: 'selection',
  SHAPE_AUTH: 'provider_user_session'
};

const ErrorReason = {
  [ErrorType.KAMBI_COUPON]: {
    GENERAL: 'general',
    LOCALIZED_GENERAL: 'localized_general',
    INSUFFICIENT_FUNDS: 'insufficient_funds',
    ACCOUNT_BLOCKED: 'account_blocked',
    TIMEOUT: 'timeout',
    REJECTED: 'rejected',
    LIMIT_EXCEEDED: 'limit_exceeded'
  },
  [ErrorType.KAMBI_BET]: {
    GENERAL: 'general',
    STAKE_TOO_HIGH: 'stake_too_high',
    STAKE_TOO_LOW: 'stake_too_low',
    STAKE_INVALID: 'stake_invalid',
    PAYOUT_TOO_HIGH: 'payout_too_high',
    CLOSED_BET: 'closed_bet',
    REJECTED: 'rejected',
    PROFIT_BOOST_STAKE_TOO_HIGH: 'profit_boost_stake_too_high',
    PROFIT_BOOST_PAYOUT_TOO_HIGH: 'profit_boost_payout_too_high',
    ODDS_BOOST_STAKE_TOO_HIGH: 'odds_boost_stake_too_high',
    ODDS_BOOST_PAYOUT_TOO_HIGH: 'odds_boost_payout_too_high'
  },
  [ErrorType.KAMBI_SELECTION]: {
    ODDS_CHANGED: 'odds_changed',
    SUSPENDED: 'suspended',
    GENERAL: 'general',
    NON_COMBINABLE: 'non_combinable',
    OUTCOME_SUSPENDED: 'outcome_suspended'
  },
  [ErrorType.SHAPE_AUTH]: {
    MISSING_TOKEN: 'Invalid or missing token'
  }
};

const getTypeReasonFromData = errorData => {
  if (errorData !== null && errorData !== void 0 && errorData.type && errorData !== null && errorData !== void 0 && errorData.reason) {
    return {
      type: errorData === null || errorData === void 0 ? void 0 : errorData.type,
      reason: errorData === null || errorData === void 0 ? void 0 : errorData.reason
    };
  } else if (errorData.message && errorData.authType) {
    return {
      type: errorData === null || errorData === void 0 ? void 0 : errorData.authType,
      reason: errorData === null || errorData === void 0 ? void 0 : errorData.message
    };
  }
  return {
    type: undefined,
    reason: undefined
  };
};
setGlobal('svs.components.oddset.common.clientErrors', {
  ErrorType,
  ErrorReason,
  getTypeReasonFromData
});

 })(window);