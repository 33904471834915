(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/common/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/oddset/common/assets/javascripts/utils.js');
"use strict";

const loggerName = 'components:oddset:common:utils';
let KambiChannel;
let logger;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  KambiChannel = require('./constants.es6').KambiChannel;
  logger = trinidad.logger(loggerName);
} else {
  KambiChannel = svs.components.oddset.common.constants.KambiChannel;
  logger = svs.core.log.getLogger(loggerName);
}
const getKambiChannel = req => {
  const channel = svs.isServer ? req.capabilities.channel : svs.core.data.capabilities.channel;
  switch (channel) {
    case 'ios-hybrid-mobile':
    case 'android-hybrid-mobile':
    case 'ios-hybrid-tablet':
    case 'web-mobile':
    case 'web-tablet':
    case 'poker-mobile':
      {
        logger.debug("Kambi login channel: ".concat(KambiChannel.MOBILE));
        return KambiChannel.MOBILE;
      }
    case 'poker-desktop':
    case 'web-desktop':
      {
        logger.debug("Kambi login channel: ".concat(KambiChannel.WEB));
        return KambiChannel.WEB;
      }
    default:
      throw new Error("Missing definition for channel ".concat(svs.core.data.capabilities.channel));
  }
};
const toExport = {
  getKambiChannel
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.oddset.common.utils', toExport);
}

 })(window);